import { template as template_c3ee1539a79d4951b1da2e3abdf7fc4a } from "@ember/template-compiler";
const WelcomeHeader = template_c3ee1539a79d4951b1da2e3abdf7fc4a(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
