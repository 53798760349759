import { template as template_d2e0b7876e9f4382896d1d241857a3ea } from "@ember/template-compiler";
const FKControlMenuContainer = template_d2e0b7876e9f4382896d1d241857a3ea(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
