import { template as template_c44a6c0bc0c94726bc043e20c347554c } from "@ember/template-compiler";
const FKText = template_c44a6c0bc0c94726bc043e20c347554c(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
