import { template as template_d89c5896261140b98575a0a5ad08a61c } from "@ember/template-compiler";
import Component from "@glimmer/component";
import AboutPageUser from "discourse/components/about-page-user";
export default class LegacyAboutPageUsers extends Component {
    get users() {
        return this.args.users || [];
    }
    static{
        template_d89c5896261140b98575a0a5ad08a61c(`
    {{#each this.users as |user|}}
      <AboutPageUser @user={{user}} />
    {{/each}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
